const rootUrl = "https://admin-api.tenetus.com/";
// const rootUrl = "http://127.0.0.1:8000/";
// 로컬 http://127.0.0.1:8000/
// 운영은 admin-api.tenetus.com
// 테스트는 admin-api-test.tenetus.com
const user = rootUrl + "user/";
const userLogin = user + "login/";
const cs = rootUrl + "cs/";
const product = rootUrl + "product/";
const report = rootUrl + "report/";
const device = rootUrl + "device/";
const udi = rootUrl + "udi/";

const master = rootUrl + "master/";
const masterUser = master + "user/";

const client = rootUrl + "client/";
const clientUser = client + "user/";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";

const api = {
  // login
  // 클라이언트 로그인 post {user_id:String, password:String}
  clientLogin: { method: POST, url: userLogin + "client/" },
  // 마스터 로그인 post {user_id:String, password:String}
  masterLogin: { method: POST, url: userLogin + "master/" },

  // 토큰 리프레시 post {refresh_token:String}
  refreshToken: { method: POST, url: user + "refresh-token/" },

  // CS
  // notice
  // 공지사항 전체 리스트 get /
  noticeList: { method: GET, url: cs + "notice/" },
  // 공지사항 작성 post {title:String, content:String}
  noticeWrite: { method: POST, url: cs + "notice/" },
  // 공지사항 단일 조회 get /targetId
  noticeDetail: { method: GET, url: cs + "notice/" },
  // 공지사항 수정 put /targetId, {title:String, content:String}
  noticeUpdate: { method: PUT, url: cs + "notice/" },
  // 공지사항 삭제 del
  noticeDelete: { method: DELETE, url: cs + "notice/" },

  // Q&A
  // Q&A 전체 리스트 get /
  qnaList: { method: GET, url: cs + "qna/" },
  // Q&A 작성 post {title:String, content:String}
  qnaWrite: { method: POST, url: cs + "qna/" },
  // Q&A 단일 조회 get /targetId
  qnaDetail: { method: GET, url: cs + "qna/" },
  // Q&A 수정 put /targetId, {title:String, content:String, answer:String}
  qnaUpdate: { method: PUT, url: cs + "qna/" },
  // Q&A 답변 put /targetId, {answer:String}
  qnaAnswer: { method: PUT, url: cs + "qna/answer/" },
  // Q&A 삭제 del
  qnaDelete: { method: DELETE, url: cs + "qna/" },

  // FAQ
  // FAQ 전체 리스트 get /
  faqList: { method: GET, url: cs + "faq/" },
  // FAQ 작성 post {category:String, title:String, content:String}
  faqWrite: { method: POST, url: cs + "faq/" },
  // FAQ 단일 조회 get /targetId
  faqDetail: { method: GET, url: cs + "faq/" },
  // FAQ 수정 put /targetId, {category:String, title:String, content:String, answer:String}
  faqUpdate: { method: PUT, url: cs + "faq/" },
  // FAQ 삭제 del
  faqDelete: { method: DELETE, url: cs + "faq/" },

  //CLIENT
  // CLIENT 전체 리스트 get /
  clientList: { method: GET, url: client },
  // CLIENT 작성 post {user_id:String, password:String, name:String, phone:String, pc_limit:Number, hmd_limit:Number}
  clientCreate: { method: POST, url: client },
  // CLIENT 단일 조회 get /targetId
  clientDetail: { method: GET, url: client },
  // CLIENT 수정 put /targetId, {user_id:String, password:String, name:String, phone:String, pc_limit:Number, hmd_limit:Number}
  clientUpdate: { method: PUT, url: client },
  // CLIENT 삭제 del
  clientDelete: { method: DELETE, url: client },
  // CLIENT agency 리스트 get /agency
  clientAgencyList: { method: GET, url: client + "agency/" },

  // PRODUCT
  // PRODUCT 전체 리스트 get /
  productList: { method: GET, url: product },
  // PRODUCT 작성 post {name:String, price:Number, description:String, image:String}
  productCreate: { method: POST, url: product },
  // PRODUCT 단일 조회 get /targetId
  productDetail: { method: GET, url: product },
  // PRODUCT 수정 put /targetId, {name:String, price:Number, description:String, image:String}
  productUpdate: { method: PUT, url: product },
  // PRODUCT 삭제 del
  productDelete: { method: DELETE, url: product },
  // PRODUCT type 전체 리스트 get /
  productTypeList: { method: GET, url: product + "type/" },
  // PRODUCT 검사명 전체 리스트 get /
  productTestList: { method: GET, url: product + "test/info/" },
  // MYPRODUCT 해제신청 put
  productCancel: { method: PUT, url: product + "cancel/" },

  // REPORT
  // REPORT 공정검사성적서 추가 post {file:File, test_id:Number}
  reportAddProcess: { method: POST, url: report + "process/" },
  // REPORT 완제품검사성적서 추가 post {file:File, test_id:Number}
  reportAddFinal: { method: POST, url: report + "final/" },
  // REPORT 설치점검표 추가 post {file:File, test_id:Number}
  reportAddCheck: { method: POST, url: report + "check/" },

  // DEVICE
  // DEVICE 전체 리스트 get /
  deviceList: { method: GET, url: device },
  // DEVICE 작성 post
  deviceCreate: { method: POST, url: device },
  // DEVICE 단일 조회 get /targetId
  deviceDetail: { method: GET, url: device },
  // DEVICE 수정 put /targetId
  deviceUpdate: { method: PUT, url: device },

  // UDI
  // UDI 전체 리스트 get /
  udiList: { method: GET, url: udi },
  // UDI 작성 post {name:String, price:Number, description:String, image:String}
  udiCreate: { method: POST, url: udi },
  // UDI 단일 조회 get /targetId
  udiDetail: { method: GET, url: udi },
  // UDI 수정 put /targetId, {name:String, price:Number, description:String, image:String}
  udiUpdate: { method: PUT, url: udi },
  // UDI 삭제 del
  udiDelete: { method: DELETE, url: udi },

  // MASTER 계정
  // MASTER 계정 전체 리스트 get /
  masterUserList: { method: GET, url: masterUser },
  // RA MASTER 목록 get /ra
  masterUserRaList: { method: GET, url: masterUser + "ra/" },
  // MASTER 계정 단일 조회 get /targetId
  masterUserDetail: { method: GET, url: masterUser },
  // MASTER 계정 생성 post {user_id:String, password:String}
  masterUserCreate: { method: POST, url: masterUser },
  // MASTER 계정 수정 put /targetId, {password:String}
  masterUserUpdate: { method: PUT, url: masterUser },
  // MASTER 계정 삭제 del
  masterUserDelete: { method: DELETE, url: masterUser },
  // MASTER 팀 리스트
  masterTeam: { method: GET, url: master + "team/" },

  // CLIENT 계정
  clientUserList: { method: GET, url: clientUser },
  // CLIENT 계정 단일 조회 get /targetId
  clientUserDetail: { method: GET, url: clientUser },
  // CLIENT 계정 생성 post {client_id:Number, user_id:String, password:String}
  clientUserCreate: { method: POST, url: clientUser },
  // CLIENT 계정 수정 put /targetId, {password:String}
  clientUserUpdate: { method: PUT, url: clientUser },
  // CLIENT 계정 삭제 del
  clientUserDelete: { method: DELETE, url: clientUser },

  // LOGINLOG
  loginLog: { method: GET, url: userLogin + "log/" },
};

export default api;
